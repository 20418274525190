import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import { getSingleMetadata, updateMetadata } from '@/@core/queries/metadata'
import i18n from '@/libs/i18n'
import realmConnection from '@/views/habit/realm'

export default function useMetadataEdit() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { route, router } = useRouter()
  const { getItem, updateItem, ObjectId } = realmConnection()
  const singleMetadata = ref({})
  const isLoading = ref(false)
  const query = { _id: ObjectId(route.value.params.id) }
  const collection = 'metadata'

  const show = async () => {
    isLoading.value = true

    try {
      const item = await getItem({ collection, query })
      if (!item) throw new Error('Item not found')

      if (item.name) item.nameId = { title: i18n.t(`metadata.${item.name}`), value: item._id.toString() }
      if (item.options) item.optionSelected = item.options[route.value.params.index]
      singleMetadata.value = item
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.metadata_fetch_error'))
    } finally {
      isLoading.value = false
    }
  }

  const update = async (data) => {
    isLoading.value = true

    try {
      if (data.options?.value) data.options.value[route.value.params.index] = data.optionSelected?.value
      const metadataOptions = Array.from(new Set(data.options.value))
  
      const payload = { options: metadataOptions }
      const action = { $set: payload }
  
      await updateItem({ collection, query, action })
  
      showSuccessMessage(i18n.t('message.metadata_option_updated'))
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.metadata_option_update_error'))
    } finally {
      router.push({ name: 'organization-metadata-list' })
      isLoading.value = false
    }
  }

  return {
    update,
    show,
    singleMetadata,
    isLoading,
  }
}
